<template>
  <div class="bg">
    <div class="bg_circle">
      <img class="circle_img circle_img_01" src="../assets/images/round_blue.png">
      <img class="circle_img circle_img_02" src="../assets/images/round_pink.png">
      <img class="circle_img circle_img_03" src="../assets/images/round_yellow.png">
      <img class="circle_img circle_img_04" src="../assets/images/round_blue.png">
      <img class="circle_img circle_img_05" src="../assets/images/round_pink.png">
      <img class="circle_img circle_img_06" src="../assets/images/round_yellow.png">

    </div>
    <div class="login">
      <div class="title">MIAS-3000 眼科OCT影像辅助诊断软件</div>
      <div class="login_wrap">
        <div class="input-box" :class="focus===1?'focus':''">
          <input auto-complete="new-password" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" type="password" v-model="oldPassword" class="linear-input-instance" placeholder="请输入原密码" @focus="focus=1" @blur="focus=0">
        </div>
        <div class="input-box" :class="focus===2?'focus':''">
          <input auto-complete="new-password" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" type="password" v-model="password" class="linear-input-instance" placeholder="请输入新密码" @focus="focus=2" @blur="focus=0">
        </div>
        <div class="input-box" :class="focus===3?'focus':''">
          <input auto-complete="new-password" onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" type="password" v-model="password_2" class="linear-input-instance" placeholder="再次输入新密码" @focus="focus=3" @blur="focus=0">
        </div>
        <el-button :disabled="disabled" class="start" type="primary" @click="save">保存</el-button>
        <router-link to="/">
          <el-button type="text" style="color:#666;">放弃修改</el-button>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import Service from '@/api/service'
import rest from "@/utils/rest";
export default {
  name: "Login",
  data() {
    return {
      focus: 0,
      password: '',
      oldPassword: '',
      password_2: '',
    }
  },
  computed: {
    disabled() {
      if (this.oldPassword && this.password && this.password_2) return false
      return true
    }
  },
  methods: {
    async save() {
      if (this.password.length < 6) {
        this.$message.error('密码至少6位数')
        return
      }
      if (this.password !== this.password_2) {
        this.$message.error('两次密码输入不一致！')
        return
      }
      let data = {
        oldPassword: this.oldPassword,
        password: this.password
      }
      await Service.modifyPassword(data)
      this.$message.success('修改成功')
      rest.logout()
      this.$router.push('/login')

    }
  }
};
</script>
<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  width: 432px;
  height: 500px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 12px 20px rgba(38, 38, 38, 0.12);
  border-radius: 12px;
  z-index: 99;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 40px;
}
.login_wrap {
  padding: 0 36px;
  height: 400px;
  margin-top: 40px;
}
.input-box {
  height: 46px;
  width: 100%;
  margin-top: 40px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}
.linear-input-instance {
  flex: 1;
  padding: 10px 0;
  line-height: 24px;
  border: none;
  min-width: 0;
  font-size: 18px;
  outline: none;
}
.focus {
  border-bottom: 1px solid #0598fa;
}
.start {
  width: 100%;
  margin-top: 40px;
}
.bg_circle {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ie .bg_circle {
  display: none;
}
.bg_circle .circle_img {
  position: absolute;
}
.bg_circle .circle_img_01 {
  max-width: 300px;
  top: 20%;
  right: 10%;
  opacity: 0.4;
  -webkit-animation: circle_img_01 14s linear infinite alternate;
  animation: circle_img_01 14s linear infinite alternate;
}
.bg_circle .circle_img_02 {
  top: 10%;
  right: 55%;
  opacity: 0.6;
  -webkit-animation: circle_img_02 22s linear infinite alternate;
  animation: circle_img_02 22s linear infinite alternate;
}
.bg_circle .circle_img_03 {
  top: 58%;
  right: -4%;
  opacity: 0.6;
  -webkit-animation: circle_img_03 14s linear infinite alternate;
  animation: circle_img_03 14s linear infinite alternate;
}
.bg_circle .circle_img_04 {
  top: 35%;
  width: 100px;
  left: -25%;
  opacity: 0.4;
  -webkit-animation: circle_img_04 14s linear infinite alternate;
  animation: circle_img_04 14s linear infinite alternate;
}
.bg_circle .circle_img_05 {
  top: 57%;
  width: 200px;
  left: 10%;
  opacity: 0.4;
  -webkit-animation: circle_img_05 14s linear infinite alternate;
  animation: circle_img_05 14s linear infinite alternate;
}
.bg_circle .circle_img_06 {
  top: 20%;
  left: 10%;
  opacity: 0.3;
  -webkit-animation: circle_img_06 14s linear infinite alternate;
  animation: circle_img_06 14s linear infinite alternate;
}
@-webkit-keyframes circle_img_01 {
  0% {
    -webkit-transform: rotate(0) scale(1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale(1.1) translate(8%, 15%);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1) translate(0, 0);
  }
}
@-webkit-keyframes circle_img_02 {
  0% {
    -webkit-transform: rotate(0) translate(-20%, 10%);
  }
  100% {
    -webkit-transform: rotate(-360deg) translate(20%, -10%);
  }
}
@-webkit-keyframes circle_img_03 {
  0% {
    -webkit-transform: rotate(0) translate(20%, 10%);
  }
  100% {
    -webkit-transform: rotate(-360deg) translate(-20%, -10%);
  }
}
@-webkit-keyframes circle_img_04 {
  0% {
    -webkit-transform: translate(0, 10%);
  }
  100% {
    -webkit-transform: translate(20%, -10%);
  }
}
@-webkit-keyframes circle_img_05 {
  0% {
    -webkit-transform: translate(-50%, 40%);
  }
  50% {
    -webkit-transform: translate(20%, 10%);
  }
  100% {
    -webkit-transform: translate(30%, -30%);
  }
}
@-webkit-keyframes circle_img_06 {
  0% {
    -webkit-transform: translate(50%, -40%);
  }
  50% {
    -webkit-transform: translate(20%, -10%);
  }
  100% {
    -webkit-transform: translate(-30%, 30%);
  }
}
@keyframes circle_img_01 {
  0% {
    transform: rotate(0) scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(8%, 15%);
  }
  100% {
    transform: rotate(360deg) scale(1) translate(0, 0);
  }
}
@keyframes circle_img_02 {
  0% {
    transform: rotate(0) translate(-20%, 10%);
  }
  100% {
    transform: rotate(-360deg) translate(20%, -10%);
  }
}
@keyframes circle_img_03 {
  0% {
    transform: rotate(0) translate(20%, 10%);
  }
  100% {
    transform: rotate(-360deg) translate(-20%, -10%);
  }
}
@keyframes circle_img_04 {
  0% {
    transform: translate(0, 10%);
  }
  100% {
    transform: translate(20%, -10%);
  }
}
@keyframes circle_img_05 {
  0% {
    transform: translate(-50%, 40%);
  }
  50% {
    transform: translate(20%, 10%);
  }
  100% {
    transform: translate(30%, -30%);
  }
}
@keyframes circle_img_06 {
  0% {
    transform: translate(50%, -40%);
  }
  50% {
    transform: translate(20%, -10%);
  }
  100% {
    transform: translate(-30%, 30%);
  }
}
</style>